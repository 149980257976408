import React from 'react';
import { Link } from 'gatsby';
import { HeaderSimple } from '../components/headers';
import LandingImageOne from '../components/images/LandingImageTwo';
import Layout from '../layout';

import LandingImageThree from '../components/images/LandingImageThree';
import SEO from '../components/SEO';
import LogoReel from '../components/images/logoreel';
import BgVideoTestimonial from '../components/bgVideoTestimonial';
import ImageQuote from '../components/images/ImageQuote';
import ItImage from '../components/images/ItImage';
import ItImageTwo from '../components/images/ItImageTwo';
import WebImage from '../components/images/WebImage';
import BookDiscoveryCall from '../components/BookDiscoveryCall';
import Quote from '../components/Quotes';
import Clients from '../components/clients';

const It = () => (
  <Layout>
    <SEO />
    <div className="bg-white py-5" />
    <div className="container-fluid p-0 mx-auto">
      <div className="container text-center text-md-left mx-auto">
        <span className="p-3 bg-themeSecondary m-3" />
        <h2 className=" display-2 align-self-center font-weight-bold  text-neutralDark">
          We create effective digital platforms
        </h2>
        <h2 className=" intro align-self-center font-weight-light  text-neutralTertiary">
          A website is an investment with an ROI expected. We always keep this in mind when building. We build your
          website with lead generation and growth in mind. From the UX/UI design, to strategic placement of call to
          actions, trust signals, brand, and tone.
        </h2>
        <br />
        <p className="lead">
          <span className="font-weight-bold">
            Discover what a website can do for you if you build with your business and marketing goals at heart.
            Discover how you capture leads, automate inquiries and install payment systems. Create landing pages and
            more, all on your website, the heart of your digital strategy. We provide such solutions to businesses in
            healthcare, e-commerce and more.
          </span>
          <br />
          <br />
          Built with industry-standard technologies and processes at all stages for maximum performance. Whether you are
          a small business needing a WordPress website to manage your content and integrations, or you are looking for a
          progressive web application that is SEO friendly. We provide web technologies suited for any type of small
          business.
        </p>
      </div>

      <div className="row p-0 bg-white align-items-start m-0">
        <div className="col-md-7  image-link-hover p-0">
          <div className="card case-study-height-auto case-study border-0 rounded-0 text-white">
            <WebImage />
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row h-100 p-2 p-md-5">
          <p className="text-center col-md-12 text-muted text-uppercase font-weight-bold mt-3 inline-block px-3 py-5">
            <span className="p-3 bg-themeSecondary m-3" />
            How we help
          </p>
          <div className="col-md-6 align-self-center">
            <ul className="list-group-flush pl-2">
              <li className="list-group-item case-text text-themePrimary border-0">Wordpress Development</li>
              <li className="list-group-item case-text text-themePrimary border-0">Gatsby JS Development</li>
              <li className="list-group-item case-text text-themePrimary border-0">Website Optimization</li>
              <li className="list-group-item case-text text-themePrimary border-0">SEO Technology </li>
              <li className="list-group-item case-text text-themePrimary border-0">Security and SSL</li>
              <li className="list-group-item case-text text-themePrimary border-0">Content Management </li>
            </ul>
          </div>
          <div className="col-md-6 align-self-center">
            <ul className="list-group-flush pl-2">
              <li className="list-group-item case-text text-themePrimary border-0">SEO Optimised Landing Pages</li>
              <li className="list-group-item case-text text-themePrimary border-0">Lead Generation Forms</li>
              <li className="list-group-item case-text text-themePrimary border-0">E-commerce </li>
              <li className="list-group-item case-text text-themePrimary border-0">User management</li>
              <li className="list-group-item case-text text-themePrimary border-0">Forums based Websites</li>
              <li className="list-group-item case-text text-themePrimary border-0">Booking systems</li>
            </ul>
          </div>
        </div>
      </div>
      <Clients clientImage={<ItImageTwo />} text="Turn more leads into sales" />
      <Quote
        video={<BgVideoTestimonial />}
        name="Andile Siziba"
        job="Director of Neurophysiology | MediServices Healthcare"
        quoteOne="Since approaching Onai Intelligence to build our website and re-brand our business, we have since seen an
                increase in our digital ROI."
        quoteTwo=" Onai has since extended our website to include other processes as well as their other Digital, IT and
                SEO services. I would highly recommend Onai to any small business"
      />
      <BookDiscoveryCall />
    </div>
  </Layout>
);

export default It;
